.cont-btn-shopping-cart {
    width: 250px;
    position: fixed;
    z-index: 10;
    right: 10px;
    bottom: 15px;

    .dropdown-menu {
        width: 250px;

        .dropdown-item {
            font-size: 11px;

            p {
                margin-bottom: 0;
            }

            i {
                font-size: 16px;
                color: $danger;
            }
        }
    }

    .dropdown-item.active,
    .dropdown-item:active {
        color: initial;
        text-decoration: none;
        background-color: initial;
    }

    .icon-cart {
        width: 60px;
        height: 60px;
        text-align: center;
        background-color: #092d4d;
        box-sizing: border-box;
        border-radius: 100%;
        padding: 0.5em;
        vertical-align: middle;
        color: white;
        margin: 0 auto;
        margin-bottom: .5em;
        position: relative;
        box-shadow: -1px 3px 5px rgba(74,74,74, .6);
        transition: all ease 0.2s;

        i {
            font-size: 2em;
            line-height: 45px;
        }
    }

    .icon-cart:hover {
        cursor: pointer;
        box-shadow: 0px 10px 10px rgb(58 57 57 / 60%);
    }

    .info-cart {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 1.1em;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5px;
        border: 1px solid #dfdfdf;
        box-shadow: -1px 3px 5px rgba(74,74,74, .6);

        .cart-total {
            margin-right: 5px;
            font-weight: bold;
        }
    }
}