.shopping-cart-detail {

    .cont-items {
        border-bottom: 1px solid $light;

        .icon-delete {
            color: $danger;
            font-size: 16px;
        }

        .icon-delete:hover {
            cursor: pointer;
        }

        .thumbnail-item {
            width: 70px;
            height: 70px;
            max-height: 70px;
            max-width: 70px;
            object-fit: cover;
            border: none;
        }

        .item-info {
            p {

                font-size: 15px !important;
            }
        }

        .cont-qty {
            .form-group:nth-child(2) {
                margin-left: 5px;
                margin-right: 5px;
            }

            .btn {
                color: $secondary;
                font-weight: bold;
                font-size: 1.2rem;
            }

            .form-control {
                text-align: center;
                width: 55px;
            }

            .btn {
                width: 50px;
            }

        }

        .item-subtotal {
            color: $dark;
            font-weight: bold;
            font-size: 18px;
        }
    }
    

    .info-total {
        font-size: 16px;
    }
}