.checkout-shopping-cart {

    .info-total{
        background-color: $light;
        padding-top: 10px;
        padding-bottom: 10px;
        
        .list-items {
            border-bottom: 1px solid white;
    
            p,h4 {
                font-size: 15px;
            }

            h4 {
                font-weight: bold;
            }
        }
    }


    .step-register {
        .cont-icon {
            display: block;
            font-size: 1.7rem;
            margin: 0 auto;
            margin-bottom: 8px;
        }
    }

    .step-payment {
        .cont-options-pay {
            .image {
                width: 100%;
                box-sizing: border-box;
                padding: 5px;
                text-align: center;
                margin: 0 auto;
                border-radius: 10px;
                box-shadow: 1px 1px 5px #f1f1f1;
                transition: all ease 0.2s;

                img {
                    width: 100px;
                    height: auto;
                }
            }

            @include MQ(M) {
                .image {
                    width: 140px;

                    img {
                        width: 130px;
                    }
                }
            }

            .image:hover {
                border: 1px solid $secondary;
                cursor: pointer;
                transition: all ease 0.2s;
            }

            .image.paymet-selected {
                border: 1px solid $secondary;
            }
        }
    }

    .step-confirmation {
        h4 {
            font-size: 18px;
            font-weight: bold;
        }
        
        p { 
            font-size: 16px;
            padding: 0;
            margin: 0;
        }

        .info-section {
            border-bottom: 1px solid #dcdcdc;
            padding-bottom: 10px;
        }
    }
}
