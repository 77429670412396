.jumbotron { background:$white; }
footer {background-color: $red; padding-top: 16px;}
footer, footer a , footer a:hover { color: $white; }
footer a { height : 20px;}
// footer svg { height : 25px; margin: 0 10px;}
footer .social-media-links a { display:inline-block; margin: 0 10px;}

ul.list2 {

	margin-left: 20px; 

	 li {
		font-size: large;
		// padding-left: 0;
		// font-size: x-large;
		list-style-image: url('/images/home/ico-list.png');
	}
}

p {
	font-size: 1.6em;
}



h2 {

	color: rgb(6, 63, 132);
	font-weight: bold;
}
.testimoniales{

	.img {
		position: absolute;
		width: 100%;
	}
	.card-body,footer {
		background-color: rgb(6, 63, 132);
	}

	.cardC {
		min-height: 33em;
		max-height: 33em;
	}

	.cardC .card-body p {

		color: #fff;
		font-style: italic;
		font-size: 1.1em;
		font-weight: light;

	}


	.cardC .card-body p.usuario-testimonial { 
		font-size: 0.9em;
		font-style: normal;
	}

}

.carousel-item img { width: 100%;}

.carousel-caption  {
	top: 80px;
	right: 10%;
	left: 35%;

	 h1 {font-size: 4.25em; font-style:italic}
	 p {font-size: 2.5em; font-weight: bold; line-height: 1.1em;}
}

ul.trabajamos-para-ti li {

	font-size: 1.4em;
}

h1.titulo-planes {

	color:#C2202B; font-family: system-ui; text-align: center;
}

.testimoniales  {
	
	.card {position:relative;}

	.card .card-body { max-height: 23em; }
	.card .card-body img {  }

	.card .thumbnail  { top:23%;  position:absolute; z-index:10;}

	.card-body {
		
	}

}

@include media-breakpoint-between(xs, md, $breakpoints: $grid-breakpoints){

	h1.titulo-planes {
 		
 		font-size: 2.3em;margin-top: 10px; margin-bottom:10px; text-align: center;
	}

	

	.marketing {
		h2 { 
				font-size: 1.8em; 
				color: rgb(6, 63, 132);
				font-weight: bold; 
			}
		p { font-size: 1.4em; }
		ul { padding-left: 2px; }
		li {font-size: 1.2em; font-weight: normal;}
	}
	.carousel-caption  {
		top: 100px;
		right: 10%;
		left: 10%;
		text-align:center;

		 h1 {font-size:3em; font-style:italic; text-align: center;}
		 p {font-size: 1.5em; font-weight: bold; line-height: 1.1em; text-align: center; margin-bottom: 40px;}
	}
}

@include media-breakpoint-up(xl, $breakpoints: $grid-breakpoints){
	// .carousel-item img { width: 100%;}
}

p { font-size: 1.4em; }


.partners {
	h3 { text-transform: uppercase; color: red; font-size: 1.1em; }
	.img-thumbnail {width: 70%; display:inline }
}

// Web images

.superrounded { border-radius: 50% !important; }

.div_banner_directorio {

}
.section-title {
	margin-top: 20px;
	// text-align: center;

	h1, h2, p { padding: 20px 0 }
	
}
.section-subtitle{

	margin-top: 20px;
	text-align: center;

	h1, h2, p { padding: 20px 0 }
	
}
section.dir-categories {
	padding: 30px 0;
	.section-title { 
		text-align: center; 
		h2 {font-size: 2.1em;}
	}
}
.directory-banner-info {
	 padding-top: 15%;
}
.card-deck div {
	margin-bottom: 10px;
}

p.card-text {
	font-size: 1em;
	a, a:hover { color: $white !important;}
}
h3.card-title {
	font-size: 1.4em;
}
.stars  {

	 i {color: $yellow; font-size: 0.6em;}
}
.card-likes {
	.fa-heart {color: $red;}
}
.card-plans .card-footer {
	border-top: none;
}


//  Adopcion

.indigo { 
	background-color: $indigo; 
	color: white;
}

.call-to-action {

}

.row-adopcion {
	margin-bottom: 40px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
	background-color: $indigo;
}
a.nav-pills , a.nav-link {
	color: $indigo;
}

// Div Call to action

div.call-to-action {
	background-color: $indigo;
	// margin: 30px 0;
	color:$white;
	h1 , p, a { text-align: center; }
}


// LOGIN FORM 

.form-login {
	width: 100%;
	max-width: 330px;
	padding: 15px;
	margin: auto;
}
.form-login .checkbox {
	font-weight: 400;
}
.form-login .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	padding: 10px;
	font-size: 16px;
}
.form-login .form-control:focus {
	z-index: 2;
}
.form-login input[type="email"] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.form-login input[type="password"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}


/// FLIP CARDS

/*
Flip card
*/
.card-flip {

	 background: none;
	 border: none;
	 padding: 0;

	 .card {
			backface-visibility: hidden;
			transition: transform 300ms;
			transition-timing-function: linear;
			width: 100%;
			height: 100%;
			margin: 0;  
	 }

	 .card:first-of-type {
			tranform: rotateY(0deg);
	 }

	 .card:last-of-type {
			transform: rotateY(180deg);
			// Put this in face with the larger content
			position: absolute;
			top: 0;
			display: flex;
	 }

	 &:hover {
			.card:first-of-type  {
				 transform: rotateY(-180deg);
			}
			.card:last-of-type {
				 transform: rotateY(0deg);
			}
	 }

}

.flip-cards{
	.card-title {
		max-width: 100%; 
		font-size: 2vw;
		margin-bottom: 0;
	}

	.card-img,
	.card-img-top,
	.card-img-bottom {   
		 min-width: 100%;
		 height: 38vh;
		 min-height: 38vh;
	}

	.text-white .card-img, .card-img {
		 filter: brightness(75%) saturate(50%);
	}
}

/*
Flip card
*/
.card-flip {

	 background: none;
	 border: none;
	 padding: 0;

	 .card {
			backface-visibility: hidden;
			transition: transform 300ms;
			transition-timing-function: linear;
			width: 100%;
			height: 100%;
			margin: 0;  
			.card-img-overlay {position: relative; background-color: $secondary; color:$white;
				h1 {font-size: 1.3em;}
			}
	 }

	 .card:first-of-type {
			tranform: rotateY(0deg);
	 }

	 .card:last-of-type {
			transform: rotateY(180deg);
			// Put this in face with the larger content
			position: absolute;
			top: 0;
			display: flex;
	 }

	 &:hover {
			.card:first-of-type  {
				 transform: rotateY(-180deg);
			}
			.card:last-of-type {
				 transform: rotateY(0deg);
			}
	 }

}
.blue { color: $indigo; }
.pink  { color: $pink ; }
.go-green { color: $go-green; }
.no-go-red { color: $red; }
.no-go-gray { color: $light-gray; }
.bg-blue {background-color: $indigo ;}

#planes {
	h3.card-title {
		font-size: 28px; font-weight: bold; text-align: center;
	}
	.plan-basico { color: $primary; }
	.plan-gold { color: $gold }
	.plan-platinum { color: $platinum }

	h4.precio { text-align: center; font-weight: bold;}
	table.tabla-planes thead th { border-bottom: none; padding-bottom: 20px; padding-top: 20px; font-size: 16px; }
	table.tabla-planes th { font-size: 20px; }
	table.tabla-planes th:not(:first-child) {text-align: center;}
	table.tabla-planes th:first-child {text-align: left; color: $red; font-size-adjust: larger}
	table.tabla-planes td, table.tabla-planes th  {  vertical-align: middle;}
}


.whatsapp-float{
	position:fixed;
	width: 50px;
	height: 50px;
	bottom: 20px;
	left: 20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:16px;
	text-align:center;
  	font-size:30px;
	box-shadow: 2px 2px 3px #999;
  	z-index:100;
}

.whatsapp-my-float{
	margin-top:10px;
	margin-left: 3px;
}

main.main { margin-top: 60px; }

nav  li { margin-right: 20px; 

	a, a.nav-link, a.dropdown-item {color: $secondary;}
}


footer {
	
	background-color: #0B3776;

	svg { margin:5px;}
	padding-top: 30px;
	h4 {
		font-size: 14px;
	}
}
ul.footer-list {
	list-style: none; padding-left: 0;
}
img.rappi-logo {width: 60px;}


.splide__slide { position:relative; margin-top: auto !important; margin-bottom:auto !important; }
.splide__slide img {
	height : auto;
	width: 60%;
	display: block;
	/*max-height: 100px;*/
	margin-left: auto ;
	margin-right: auto ;
}
.splide__list {
	// height: 160px;
}
button.splide__arrow, button.splide__arrow:hover  {
	background-color: white !important;
	opacity: 1.0 !important;

		.splide__arrow--next {

		}
	}
.splide__pagination {
	
	.splide__pagination__page:hover , .splide__pagination__page.is-active{
		background-color:#0B3776;
	}

}


.splide__arrow svg {
	fill: #0B3776  !important;
}





.logos{
	// display:flex;
	svg,g{
		width: 2em;
		height: 2em;
	}
}
