.card-plans {

	border: none;
    
    h1, h2, h3, h4, h5, h6 , p {
    	color: white; 
		
		.card-text {
			font-size: smaller;
		}
	}

	h4.card-title { text-align:center !important; font-size:14px; font-weight:bold; }

    .card_body, .container-card {
		
		ul, li{ list-style:none ; margin-left:0;}
		
		ul{
			padding-left:0px;

	 		li {
	 			font-size: 13px;
	 			padding-left:16px;
				background-image: url('/images/home/idanimal-placas_para_perros-chip-mascotas-chip_para_perros-gatos-patita.png');
				background-size: 14px;
				background-repeat: no-repeat;
				background-position: left top;
				list-style: none;
				line-height: 18px;
				
			}
		}
    }
}
.tienda .row .container h2 { text-align:center }


.price {
	h1, h2, h3 , h4, h5, h6 {
		text-align: center;
	}
} 

.btn-comprar {
	font-weight: bold;
	padding: 0px 18px;
	font-size: 24px;
	border-radius: 1rem;
	background-color: #16B827;
	span { margin: 0; padding: 0; }
}

#plan_basic, #plan_platinum { margin-top: 20%; background-color: #063f84;}
#plan_gold {transform: scale(1.04,1.04) ; z-index: 10; background-color:#0A4992;
	.card-body{background-color:#0A4992; 
		li {
			line-height: 22px;
		}
	}
}

@include media-breakpoint-between(xs, sm, $breakpoints: $grid-breakpoints){

	#plan_basic, #plan_platinum, #plan_gold  { margin-bottom: 10px; }
	#plan_basic, #plan_platinum { margin-top: 0; }

	#plan_gold {transform: scale(1.0,1.0) ; z-index: 10; background-color:#0A4992;}


}