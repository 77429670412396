.modal-plane-care {
    .modal-header {
        border: none;
    }

    .img-plan {
        width: 100%;
        height: auto;
    }

    .cont-info {
        .price {
            color: $primary;
        }

        .cont-tabs {
            margin-top: 2rem;

            .nav-link {
                background: transparent;
                color: $gray;
                // border-bottom: 1px solid $gray;
                border-radius: 0;
            }

            .nav-link.active {
                background: transparent;
                color: $secondary;
                border-bottom: 1px solid $secondary;
            }
        }
    }


    .frm-options {
        span {
            color: $gray;
        }

        .cont-colors {
            .cont-color {
                display: inline-block;
                width: 40px;
                height: 40px;
                background-color: $ligth;
                box-sizing: border-box;
                padding: 5px;
                position: relative;
                transition: all ease 0.2s;

                .color { 
                    width: 30px;
                    height: 30px;
                }

                .color.black {
                    background-color: black;
                }

                .color.green {
                    background-color: #59b47c;
                }

                .color.blue {
                    background-color: #2689c3;
                }

                .color.pink {
                    background-color: #e7404b;
                }
            }
            
            .cont-color:hover {
                cursor: pointer;
                border: 2px solid $gray;
                padding: 3px;
                transition: all ease 0.2s;
            }

            .cont-color.active-color {
                border: 2px solid $primary;
                padding: 3px;
            }
        }

        .cont-qty {
            .form-group:nth-child(2) {
                margin-left: 5px;
                margin-right: 5px;
            }

            .btn {
                color: $secondary;
                font-weight: bold;
                font-size: 1.2rem;
            }

            .form-control {
                text-align: center;
            }
        }
    }
}

.only-one-plan {
    .cont-colors {
        .cont-color {
            display: inline-block;
            width: 40px;
            height: 40px;
            background-color: $ligth;
            box-sizing: border-box;
            padding: 5px;
            position: relative;
            transition: all ease 0.2s;

            .color { 
                width: 30px;
                height: 30px;
            }

            .color.black {
                background-color: black;
            }

            .color.green {
                background-color: #59b47c;
            }

            .color.blue {
                background-color: #2689c3;
            }

            .color.pink {
                background-color: #e7404b;
            }
        }
        
        .cont-color:hover {
            cursor: pointer;
            border: 2px solid $gray;
            padding: 3px;
            transition: all ease 0.2s;
        }

        .cont-color.active-color {
            border: 2px solid $primary;
            padding: 3px;
        }
    }
}