.cd-breadcrumb, .cd-multi-steps {
	width: 90%;
	max-width: $M;
	padding: 0.5em 1em;
	margin: 1em auto;
	background-color: $light;
	border-radius: .25em;
	@include clearfix;

	li {
		display: inline-block;
		float: left;
		margin: 0.5em 0;

		&::after {
			/* this is the separator between items */
			display: inline-block;
			content: '\00bb';
			margin: 0 .6em;
			color: tint($gray, 50%);
		}

		&:last-of-type::after {
			/* hide separator after the last item */
			display: none;
		}
	}
	
	li > * {
		/* single step */
		display: inline-block;
		font-size: 15px;
		color: $gray;
	}

	li.current > * {
		/* selected step */
		color: $dark;
	}

	li.visited > * {
		/* selected step */
		color: $primary;
	}

	.no-touch & a:hover {
		/* steps already visited */
		color: $primary;
	}

	&.custom-separator li::after {
		/* replace the default arrow separator with a custom icon */
		content: '';
		height: 16px;
		width: 16px;
		background: url(../images/web/cd-custom-separator.svg) no-repeat center center;
		vertical-align: middle;
	}

	&.custom-icons li > *::before {
		/* add a custom icon before each item */
		content: '';
		display: inline-block;
		height: 20px;
		width: 20px;
		margin-right: .4em;
		margin-top: -2px;
		background: url(../images/web/cd-custom-icons-01.svg) no-repeat 0 0;
		vertical-align: middle;
	}

	&.custom-icons li:not(.current):nth-of-type(2) > *::before {
		/* change custom icon using image sprites */
		background-position: -20px 0;
	}

	&.custom-icons li:not(.current):nth-of-type(3) > *::before {
		background-position: -40px 0;
	}

	&.custom-icons li:not(.current):nth-of-type(4) > *::before {
		background-position: -60px 0;
	}

	&.custom-icons li.current:first-of-type > *::before {
		/* change custom icon for the current item */
		background-position: 0 -20px;
	}

	&.custom-icons li.current:nth-of-type(2) > *::before {
		background-position: -20px -20px;
	}

	&.custom-icons li.current:nth-of-type(3) > *::before {
		background-position: -40px -20px;
	}

	&.custom-icons li.current:nth-of-type(4) > *::before {
		background-position: -60px -20px;
	}

	@include MQ(M) {
		padding: 0 1.2em;

		li {
			margin: 1.2em 0;

			&::after {
				margin: 0 1em;
			}
		}

		li > * {
			font-size: 15px;
		}
	}
}

@include MQ(M) {

	.cd-multi-steps {
		/* reset style */
		background-color: transparent;
		padding: 0;
		text-align: center;
	}

	.cd-multi-steps li {
		position: relative;
		float: none;
		margin: 0.4em 40px 0.4em 0;

		&:last-of-type {
			margin-right: 0;
		}

		&::after {
			/* this is the line connecting 2 adjacent items */
			position: absolute;
			content: '';
			height: 4px;
			background: $light;
			/* reset style */
			margin: 0; 
		}

		&.visited::after {
			background-color: $primary;
		}

		& > *, &.current > * {
			position: relative;
			color: $gray;
		}

		&.current {
			em {
				color: $dark;
			}
		}

		&.visited {
			em {
				color: $primary important;
			}
		}
	}

	li.current {
		em {
			color: $dark;
		}
	}

	li.visited {
		em {
			color: $primary !important;
		}
	}

	.cd-multi-steps.custom-separator li::after {
		/* reset style */
		height: 4px;
		background: $light;
	}

	.cd-multi-steps.text-center {

		li::after {
			width: 100%;
			top: 50%;
			left: 100%;
			// @include transform(translateY(-50%) translateX(-1px));
		}

		li > * {
			z-index: 1;
			padding: .6em 1em;
			border-radius: .25em;
			background-color: $light;
		}

		.no-touch & a:hover {
			background-color: $gray;
		}

		li.current > *, li.visited > * {
			color: white;
			background-color: $primary;
		}

		&.custom-icons li.visited a::before {
			/* change the custom icon for the visited item - check icon */
			background-position: 0 -60px;
		}
	}

	.cd-multi-steps.text-top, .cd-multi-steps.text-bottom { 
		
		li {
			width: 80px;
			text-align: center;

			&::after {
				/* this is the line connecting 2 adjacent items */
				position: absolute;
				left: 50%;
				/* 40px is the <li> right margin value */
				width: calc(100% + 40px);
			}
		}

		li > *::before {
			/* this is the spot indicator */
			content: '';
			position: absolute;
			z-index: 1;
			@include center(x);
			height: 12px;
			width: 12px;
			border-radius: 50%;
			background-color: $light; 	
		}

		li.visited > *::before, 
		li.current > *::before {
			background-color: $primary;
		}

		.no-touch & a:hover {
			color: $primary;

			&::before {
				box-shadow: 0 0 0 3px rgba($primary, .3);
			}
		}
	}

	.cd-multi-steps.text-top {

		li::after {
			/* this is the line connecting 2 adjacent items */
			bottom: 4px;
		}

		li > * {
			padding-bottom: 20px;

			&::before {
				/* this is the spot indicator */
				bottom: 0;
			}	
		}
	}

	.cd-multi-steps.text-bottom {

		li::after {
			/* this is the line connecting 2 adjacent items */
			top: 3px;
		}

		li > * {
			padding-top: 20px;

			&::before {
				/* this is the spot indicator */
				top: 0;
			}	
		}
	}
}