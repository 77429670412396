.alert {
    position: fixed;
    z-index: 20;
    bottom: 0.5em;
    left: 50%;
    transform: translate(-50%);
    width: 95%;
    max-width: 95%;

    @media #{$media-medium} {
        width: 75%;
        max-width: 75%;
    }

    @media #{$media-large} {
        width: 45%;
        max-width: 45%;
    }

    .alert-icon::before {
        display: inline-block;
        content: "";
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
    }
}

.alert.show {
    z-index: 100;
}

.alert.fade:not(.show) {
    z-index: -100;
}

.alert.alert-info { 
    .alert-icon::before {
        background-image: url("data:image/svg+xml,<svg fill='rgba(" + hexToString($info) + ")' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z'/></svg>");
    }
}

.alert.alert-light { 
    .alert-icon::before {
        background-image: url("data:image/svg+xml,<svg fill='rgba(" + hexToString($gray-contrast) + ")' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z'/></svg>");
    }
}

.alert.alert-secondary { 
    .alert-icon::before {
        background-image: url("data:image/svg+xml,<svg fill='rgba(" + hexToString($secondary) + ")' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z'/></svg>");
    }
}

.alert.alert-dark { 
    .alert-icon::before {
        background-image: url("data:image/svg+xml,<svg fill='rgba(" + hexToString($dark) + ")' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z'/></svg>");
    }
}

.alert.alert-primary { 
    .alert-icon::before {
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='rgba(" + hexToString($primary) + ")' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'/></svg>");
    }
}

.alert.alert-success { 
    .alert-icon::before {
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='rgba(" + hexToString($success) + ")' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'/></svg>");
    }
}

.alert.alert-warning { 
    .alert-icon::before {
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='rgba(" + hexToString($warning) + ")' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'/></svg>");
    }
}

.alert.alert-danger { 
    .alert-icon::before {
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='rgba(" + hexToString($danger) + ")' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.146-3.146a.5.5 0 0 0-.708-.708L8 7.293 4.854 4.146a.5.5 0 1 0-.708.708L7.293 8l-3.147 3.146a.5.5 0 0 0 .708.708L8 8.707l3.146 3.147a.5.5 0 0 0 .708-.708L8.707 8l3.147-3.146z'/></svg>");
    }
}